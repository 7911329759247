import { useNavigate } from 'react-router-dom';

import { Navbar as BsNavbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { logout } from 'helpers';
import {
    useGetIsLoggedIn,
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import { routeNames } from 'routes';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import Logo from 'assets/img/logo.png';
import utxsvg from 'assets/img/utx.svg';
import membershipcard from 'assets/img/swap-membership-icon.png';
import ecosys from 'assets/img/ecosys.png';
import evcyborg from 'assets/img/cyborg.png';
import oddgang from 'assets/img/OAG.png';

export const Navbar = () => {
    const navigate = useNavigate();

    const isLoggedIn = useGetIsLoggedIn();
    const { success } = useGetActiveTransactionsStatus();
    const { address, username } = useGetAccount();
    const {
        network: { apiAddress, chainID }
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

    const navbarExpand = 'md';

    const handleLogout = () => {
        logout(`${window.location.origin}/unlock`);
    };

    return (
        <BsNavbar expand={navbarExpand} className=''>
            <div className='d-flex flex-column'>
                <BsNavbar.Brand className='d-flex flex-column'>
                    <Link
                        className='d-flex flex-column align-items-center navbar-brand mr-0'
                        to={isLoggedIn ? routeNames.dashboard : routeNames.home}
                    >
                        <img src={Logo} alt='logo' className='logo' />
                    </Link>
                    <div className='social-links d-flex flex-column align-items-center text-center'>
                        <button
                            onClick={() => navigate(routeNames.dashboard)}
                            className='lateral-menu-icon'
                            style={{
                                backgroundColor:
                                    window.location.pathname ===
                                    routeNames.dashboard
                                        ? '#631ee3'
                                        : ''
                            }}
                        >
                            <svg
                                fill={'#fff'}
                                width='32'
                                height='32'
                                xmlns='http://www.w3.org/2000/svg'
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                viewBox='0 0 24 24'
                            >
                                <path d='M13 23h-10l-.002-10.016 8.974-7.989 9.011 7.989.017 10.016h-3v-7h-5v7zm-6-7h-2v3h2v-3zm4 0h-2v3h2v-3zm1-15l11.981 10.632-1.328 1.493-10.672-9.481-10.672 9.481-1.328-1.493 12.019-10.632z' />
                            </svg>
                        </button>

                        <button
                            onClick={() => navigate(routeNames.infinityStaking)}
                            className='lateral-menu-icon'
                            style={{
                                backgroundColor:
                                    window.location.pathname ===
                                    routeNames.infinityStaking
                                        ? '#631ee3'
                                        : ''
                            }}
                        >
                            <img
                                src={evcyborg}
                                alt='logo'
                                className='logo'
                                style={{ maxHeight: 32 }}
                            />
                        </button>
                        <button
                            onClick={() => navigate(routeNames.oagStaking)}
                            className='lateral-menu-icon'
                            style={{
                                backgroundColor:
                                    window.location.pathname ===
                                    routeNames.oagStaking
                                        ? '#631ee3'
                                        : ''
                            }}
                        >
                            <img
                                src={oddgang}
                                alt='logo'
                                className='logo'
                                style={{ maxHeight: 32 }}
                            />
                        </button>
                        <button
                            onClick={() =>
                                navigate(routeNames.collectionsStaking)
                            }
                            className='lateral-menu-icon'
                            style={{
                                backgroundColor:
                                    window.location.pathname ===
                                    routeNames.collectionsStaking
                                        ? '#631ee3'
                                        : ''
                            }}
                        >
                            <img
                                src={ecosys}
                                alt='logo'
                                className='logo'
                                style={{ maxHeight: 32 }}
                            />
                        </button>
                        <button
                            onClick={() => navigate(routeNames.tokenStaking)}
                            className='lateral-menu-icon'
                            style={{
                                backgroundColor:
                                    window.location.pathname ===
                                    routeNames.tokenStaking
                                        ? '#631ee3'
                                        : ''
                            }}
                        >
                            <img
                                src={utxsvg}
                                alt='logo'
                                className='logo'
                                style={{ maxHeight: 32 }}
                            />
                        </button>

                        <button
                            onClick={() => navigate(routeNames.membershipSwap)}
                            className='lateral-menu-icon'
                            style={{
                                backgroundColor:
                                    window.location.pathname ===
                                    routeNames.membershipSwap
                                        ? '#631ee3'
                                        : ''
                            }}
                        >
                            <img
                                src={membershipcard}
                                alt='logo'
                                className='logo'
                                style={{ maxHeight: 32 }}
                            />
                        </button>
                        <button
                            className='lateral-menu-icon d-md-none'
                            onClick={() => navigate(0)}
                        >
                            <a href='#' rel='noopener noreferrer'>
                                <svg
                                    clip-rule='evenodd'
                                    width='32'
                                    height='32'
                                    fill={
                                        routeNames.dashboard === '/data'
                                            ? '#631ee3'
                                            : '#fff'
                                    }
                                    fill-rule='evenodd'
                                    stroke-linejoin='round'
                                    stroke-miterlimit='2'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='m11.998 2.001c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-4.496 6.028-.002-.825c0-.414-.336-.75-.75-.75s-.75.336-.75.75v3.048c0 .414.336.75.75.75h3.022c.414 0 .75-.336.75-.75s-.336-.756-.75-.756h-1.512c.808-1.205 2.182-1.998 3.74-1.998 2.483 0 4.5 2.016 4.5 4.5 0 2.483-2.017 4.5-4.5 4.5-1.956 0-3.623-1.251-4.242-2.997-.106-.299-.389-.499-.707-.499-.518 0-.88.513-.707 1.001.825 2.327 3.048 3.995 5.656 3.995 3.312 0 6-2.689 6-6 0-3.312-2.688-6-6-6-1.79 0-3.399.786-4.498 2.031z'
                                        fill-rule='nonzero'
                                    />
                                </svg>
                            </a>
                        </button>
                    </div>
                </BsNavbar.Brand>
            </div>
        </BsNavbar>
    );
};

export const shortenAddress = (address: string, length: number = 8) => {
    return (
        address.substring(0, length) +
        '...' +
        address.substring(address.length - length, address.length)
    );
};
