import { useEffect, useState } from 'react';
import { NonFungibleToken, gqlTokenStaking } from 'types';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import { utxIdentifier, utxDecimals } from 'config';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import {
    TokenPayment,
    TransferTransactionsFactory,
    GasEstimator,
    Address
} from '@multiversx/sdk-core';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import {
    string2hex,
    bignumber2hex,
    number2hex,
    timestampToDate
} from 'helpers';
import CountUp from 'react-countup';
import BigNumber from 'bignumber.js';
import MultiversxSymbol from 'assets/img/multiversx-symbol.svg';
import UtxLogo from 'assets/img/utx.png';
import LpLogo from 'assets/img/lp.png';
import Decimal from 'decimal.js';

export const SECONDS_IN_DAY = 60 * 60 * 24;

export const TokenStakingPoolComponent = ({
    pool: {
        _address,
        rewardsForUser,
        stakingToken,
        membershipToken,
        rewardToken,
        userStaking,
        currentUserBoost,
        userAutoRestakeDeadline,
        userUnstaking,
        unstakingPeriod,
        fastUnstakingPenalty
    },
    tokenPrice
}: {
    pool: gqlTokenStaking;
    tokenPrice: Decimal | undefined;
}) => {
    if (
        _address === undefined ||
        rewardsForUser === undefined ||
        stakingToken === undefined ||
        membershipToken === undefined ||
        rewardToken === undefined ||
        userStaking === undefined ||
        currentUserBoost === undefined ||
        userAutoRestakeDeadline === undefined ||
        userUnstaking === undefined ||
        unstakingPeriod === undefined ||
        fastUnstakingPenalty === undefined
    ) {
        return null;
    }

    const { address } = useGetAccount();

    const {
        network: { apiAddress, chainID }
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);
    const { success, fail } = useGetActiveTransactionsStatus();

    const [expanded, setExpanded] = useState<boolean>(false);

    const [inputAmountToStake, setInputAmountToStake] = useState<string>('');
    const [inputAmountToUnstake, setInputAmountToUnstake] =
        useState<string>('');
    const [inputMembershipCard, setInputMembershipCard] = useState<string>('');
    const [inputMembershipCardAutoRestake, setInputMembershipCardAutoRestake] =
        useState<string>('');

    const [walletNfts, setWalletNfts] = useState<NonFungibleToken[]>([]);
    const [walletNftsAutoRestake, setWalletNftsAutoRestake] = useState<
        NonFungibleToken[]
    >([]);

    const currentTimestamp = Math.floor(Date.now() / 1000);

    const [stakingTokenUserBalance, setStakingTokenUserBalance] =
        useState<BigNumber | null>();

    const getNumberTypeFromColor = (color: string) => {
        if (color === 'Diamond') {
            return 3;
        }
        if (color === 'Gold') {
            return 2;
        }
        return 1;
    };

    useEffect(() => {
        if (address) {
            apiNetworkProvider
                .getAccountTokenBalance(address, stakingToken)
                .then((balance) => setStakingTokenUserBalance(balance));
        }
    }, [address, success]);

    // Fetch wallet NFTs (membership cards)
    useEffect(() => {
        apiNetworkProvider
            .getAccountNftsFromCollection(address, membershipToken)
            .then((res) => {
                const walletsToShow = res.filter((nft) => {
                    if (
                        !currentUserBoost ||
                        (currentUserBoost.boost_deadline ?? 0) <
                            currentTimestamp
                    ) {
                        return true;
                    }
                    const color = nft.metadata?.attributes?.find(
                        (attr: any) => attr.trait_type === 'Color'
                    )?.value;
                    const numberType = getNumberTypeFromColor(color);
                    const boostedNumberType = getNumberTypeFromColor(
                        currentUserBoost.card_type
                    );
                    if (numberType > boostedNumberType) {
                        return true;
                    }
                    return false;
                });
                setWalletNfts(walletsToShow);
                if (walletsToShow.length > 0) {
                    setInputMembershipCard(walletsToShow[0].identifier);
                }

                //auto restake
                const walletsToShowAutoRestake = res.filter((nft) => {
                    const color = nft.metadata?.attributes?.find(
                        (attr: any) => attr.trait_type === 'Color'
                    )?.value;
                    return getNumberTypeFromColor(color) === 1;
                });
                setWalletNftsAutoRestake(walletsToShowAutoRestake);
                if (walletsToShowAutoRestake.length > 0) {
                    setInputMembershipCardAutoRestake(
                        walletsToShowAutoRestake[0].identifier
                    );
                }
            });
    }, [success]);

    const stake = async () => {
        const payload =
            new TransferTransactionsFactory(new GasEstimator())
                .createESDTTransfer({
                    tokenTransfer: TokenPayment.fungibleFromAmount(
                        stakingToken,
                        new BigNumber(inputAmountToStake),
                        utxDecimals
                    ),
                    receiver: new Address(_address),
                    sender: new Address(address),
                    chainID
                })
                .getData()
                .toString() +
            '@' +
            string2hex('stake');

        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: payload,
                receiver: _address,
                gasLimit: 20_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Staking Tokens...',
                errorMessage: 'An error has occured during staking',
                successMessage: 'Tokens staked successfully!'
            }
        });
        setInputAmountToStake('');
    };

    const boost = async () => {
        const nftToSend = walletNfts.find(
            (nft) => nft.identifier === inputMembershipCard
        )!;
        const payload =
            new TransferTransactionsFactory(new GasEstimator())
                .createESDTNFTTransfer({
                    tokenTransfer: TokenPayment.nonFungible(
                        nftToSend.collection,
                        nftToSend.nonce
                    ),
                    destination: new Address(_address),
                    sender: new Address(address),
                    chainID
                })
                .getData()
                .toString() +
            '@' +
            string2hex('addMembershipCard');

        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: payload,
                receiver: address,
                gasLimit: 20_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Boosting position...',
                errorMessage: 'An error has occured during boost',
                successMessage: 'Position boosted successfully!'
            }
        });
    };

    const optInAutoRestake = async () => {
        const nftToSend = walletNftsAutoRestake.find(
            (nft) => nft.identifier === inputMembershipCardAutoRestake
        )!;
        const payload =
            new TransferTransactionsFactory(new GasEstimator())
                .createESDTNFTTransfer({
                    tokenTransfer: TokenPayment.nonFungible(
                        nftToSend.collection,
                        nftToSend.nonce
                    ),
                    destination: new Address(_address),
                    sender: new Address(address),
                    chainID
                })
                .getData()
                .toString() +
            '@' +
            string2hex('optInAutoRestake');

        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: payload,
                receiver: address,
                gasLimit: 20_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Enabling AutoRestake...',
                errorMessage: 'An error has occured during enable',
                successMessage: 'AutoRestake enabled successfully!'
            }
        });
    };

    const optOutAutoRestake = async () => {
        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: 'optOutAutoRestake',
                receiver: _address,
                gasLimit: 20_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Enabling AutoRestake...',
                errorMessage: 'An error has occured during enable',
                successMessage: 'AutoRestake enabled successfully!'
            }
        });
    };

    const unstake = async (fastUnstake: boolean = false) => {
        await refreshAccount();
        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data:
                    'unstake@' +
                    bignumber2hex(
                        new BigNumber(inputAmountToUnstake).multipliedBy(
                            new BigNumber(10).pow(utxDecimals)
                        )
                    ) +
                    '@' +
                    (fastUnstake ? '01' : ''),
                receiver: _address,
                gasLimit: 20_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Unstaking Tokens...',
                errorMessage: 'An error has occured during unstake',
                successMessage: 'Tokens unstaked successfully!'
            }
        });
        setInputAmountToUnstake('');
    };

    const withdraw = async (id: number) => {
        await refreshAccount();
        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: 'withdraw@' + number2hex(id),
                receiver: _address,
                gasLimit: 20_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Withdrawing Tokens...',
                errorMessage: 'An error has occured during withdrawing',
                successMessage: 'Tokens withdrawn successfully!'
            }
        });
    };

    const cancelUnstake = async (id: number) => {
        await refreshAccount();
        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: 'cancelUnstake@' + number2hex(id),
                receiver: _address,
                gasLimit: 20_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Cancelling unstake...',
                errorMessage: 'An error has occured during cancel',
                successMessage: 'Unstake cancelled successfully!'
            }
        });
    };

    const fastWithdraw = async (id: number) => {
        await refreshAccount();
        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: 'fastWithdraw@' + number2hex(id),
                receiver: _address,
                gasLimit: 20_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Fast Withdraw Tokens...',
                errorMessage: 'An error has occured during fast withdrawing',
                successMessage: 'Tokens fast withdrawn successfully!'
            }
        });
    };

    const claimRewards = async () => {
        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: 'claim_rewards',
                receiver: _address,
                gasLimit: 25_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Claiming rewards...',
                errorMessage: 'An error has occured during claiming',
                successMessage: 'Rewards claimed successfully!'
            }
        });
    };

    const restakeRewards = async () => {
        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: 'restake_rewards',
                receiver: _address,
                gasLimit: 25_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Restaking rewards...',
                errorMessage: 'An error has occured during restaking',
                successMessage: 'Rewards restaked successfully!'
            }
        });
    };

    const tokenToPath = (token?: string) => {
        if (token === utxIdentifier) {
            return UtxLogo;
        }
        if (token === 'UNITYWEGLD-c20c3a') {
            return LpLogo;
        }
        return MultiversxSymbol;
    };

    const addressToName = (address: string) => {
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqqr8y7v7u8z4r5ral9ytcr9dfs6g9hmhhdn3qvk485n'
        ) {
            return 'Normal Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgq6sqyxxr9mjc3w90czqmh4dwdkfg9yz39dn3qqkylyk'
        ) {
            return 'LP Farm';
        }
        return 'High Pool';
    };

    const addressToAprs = (address: string) => {
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqqr8y7v7u8z4r5ral9ytcr9dfs6g9hmhhdn3qvk485n'
        ) {
            return ['17.5', 21, '22.7', '26.2'];
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgq6sqyxxr9mjc3w90czqmh4dwdkfg9yz39dn3qqkylyk'
        ) {
            return ['~50', '~60', '~65', '~75'];
        }
        return [35, 42, '45.5', '52.5'];
    };

    //console.log(userUnstaking.length);

    return (
        <div className='token-staking-container mb-4 card'>
            <label
                htmlFor={'check-' + _address}
                className={
                    'nft-staking-card ' +
                    (expanded ? 'nft-staking-expanded' : '')
                }
            >
                <div className='nft-staking-card-header'>
                    <span className='nft-title'>
                        {addressToName(_address)} - {addressToAprs(_address)[0]}
                        % APR
                    </span>

                    <span
                        className='nft-rewards d-flex flex-column'
                        style={{ marginBottom: 'auto', marginTop: 'auto' }}
                    >
                        <span className='text-light'>Rewards:&nbsp;</span>

                        <div className='d-flex align-items-center'>
                            <img
                                src={tokenToPath(rewardToken)}
                                alt='Logo'
                                className='nft-token-icon mr-2'
                            />

                            <CountUp
                                end={new BigNumber(rewardsForUser)
                                    .dividedBy(10 ** utxDecimals)
                                    .toNumber()}
                                decimals={5}
                                duration={3}
                                useEasing={true}
                                preserveValue={true}
                            />
                            {tokenPrice && (
                                <span className='mx-2 equivalent-usdc'>
                                    ~&nbsp;
                                    {new Decimal(rewardsForUser.toString(10))
                                        .div(10 ** 18)
                                        .mul(tokenPrice)
                                        .toSignificantDigits(3)
                                        .toString()}
                                    $
                                </span>
                            )}
                        </div>
                    </span>

                    <div>
                        <button
                            className='claim-button btn btn-secondary btn-custom px-4 mr-4'
                            disabled={rewardsForUser!.toString(10) === '0'}
                            onClick={() => claimRewards()}
                        >
                            Claim
                        </button>
                        {rewardToken === stakingToken && (
                            <button
                                className='claim-button btn btn-secondary btn-custom px-4 mr-4'
                                disabled={rewardsForUser!.toString(10) === '0'}
                                onClick={() => restakeRewards()}
                            >
                                Restake
                            </button>
                        )}
                        <FontAwesomeIcon
                            icon={expanded ? faChevronUp : faChevronDown}
                            className='ml-auto my-auto fa-lg arrow-custom mr-2'
                        />
                    </div>
                </div>

                <input
                    type='checkbox'
                    className='form-control form-check-input nft-checkbox d-none '
                    id={'check-' + _address}
                    checked={expanded}
                    onChange={(e) => setExpanded((prev) => !prev)}
                />
            </label>

            <div
                className={
                    'nft-staking-card-body utx-staking pt-4 ' +
                    (expanded ? 'expanded' : 'd-none')
                }
            >
                <div className='w-100 d-flex flex-row text-center justify-content-evenly mb-4 card-container'>
                    <div className='w-50 d-flex card card-dashboard p-4 m-2'>
                        <h4>Staked Tokens:</h4>
                        <div className='w-fit h-100 align-items-center d-flex flex-row text-center justify-content-center'>
                            <img
                                src={tokenToPath(stakingToken)}
                                alt='Logo'
                                className='nft-token-icon mr-2 ml-2'
                            />
                            <FormatAmount
                                value={
                                    userStaking?.staked_amount?.toString(10) ??
                                    '0'
                                }
                                token={stakingToken.split('-')[0]}
                                digits={4}
                                decimals={utxDecimals}
                            />
                            {tokenPrice && stakingToken === utxIdentifier && (
                                <span className='mx-2 equivalent-usdc'>
                                    ~&nbsp;
                                    {new Decimal(
                                        userStaking?.staked_amount?.toString(
                                            10
                                        ) ?? '0'
                                    )
                                        .div(10 ** 18)
                                        .mul(tokenPrice)
                                        .toSignificantDigits(3)
                                        .toString()}
                                    $
                                </span>
                            )}
                        </div>
                        {_address ===
                            'erd1qqqqqqqqqqqqqpgq6sqyxxr9mjc3w90czqmh4dwdkfg9yz39dn3qqkylyk' && (
                            <a
                                href='https://arda.run/swap?from=single-wallet-EGLD&to=lpXexch-wallet-UNITYWEGLD-c20c3a'
                                target='_blank'
                                className='btn mt-2 px-4 btn-outline-primary section-toggler-custom'
                            >
                                Add Liquidity
                            </a>
                        )}
                    </div>

                    <div className='apr-list w-50 d-flex card card-dashboard p-4 m-2 apr-class'>
                        <h4>APR:</h4>
                        <div className=' w-fit d-flex flex-row text-center justify-content-center'>
                            🥉Normal: {addressToAprs(_address)[0]}%<br />
                            🥈Common Card: {addressToAprs(_address)[1]}%
                            <br />
                            🥇Gold Card: {addressToAprs(_address)[2]}%
                            <br />
                            💎Diamond Card: {addressToAprs(_address)[3]}%
                        </div>
                    </div>
                </div>
                <div className='w-100 d-flex flex-row text-center justify-content-evenly mb-4 card-container'>
                    <div className='w-50 d-flex card card-dashboard p-4 m-2'>
                        <h4>Stake:</h4>
                        <div className='input-group mb-2'>
                            <input
                                type='number'
                                className='form-control input-amount'
                                placeholder='Enter amount to stake'
                                value={inputAmountToStake}
                                onChange={(e) =>
                                    setInputAmountToStake(e.target.value)
                                }
                            />
                            <div className='input-group-append'>
                                <button
                                    className='btn btn-outline-secondary'
                                    type='button'
                                    onClick={() => {
                                        setInputAmountToStake(
                                            new BigNumber(
                                                stakingTokenUserBalance ?? '0'
                                            )
                                                .dividedBy(
                                                    new BigNumber(10).pow(
                                                        utxDecimals
                                                    )
                                                )
                                                .toString(10)
                                        );
                                    }}
                                >
                                    MAX
                                </button>
                            </div>
                        </div>
                        <button
                            className='btn px-4 btn-outline-primary section-toggler-custom'
                            onClick={() => stake()}
                            disabled={stakingTokenUserBalance?.isLessThan(
                                new BigNumber(inputAmountToStake).multipliedBy(
                                    new BigNumber(10).pow(utxDecimals)
                                )
                            )}
                        >
                            Stake
                        </button>
                        <h6 className='mt-2'>
                            Available:{' '}
                            {stakingTokenUserBalance && (
                                <FormatAmount
                                    value={stakingTokenUserBalance.toString(10)}
                                    token={stakingToken.split('-')[0]}
                                    digits={5}
                                    decimals={utxDecimals}
                                />
                            )}
                        </h6>
                    </div>
                    <div className='w-50 d-flex card card-dashboard p-4 m-2'>
                        <h4>Boost:</h4>
                        <div className='fs-6'>
                            {currentUserBoost &&
                            currentUserBoost.boost_deadline! >
                                currentTimestamp ? (
                                <div className='boosted-true'>
                                    Your position is boosted with a{' '}
                                    {currentUserBoost.card_type} Card until{' '}
                                    {timestampToDate(
                                        currentUserBoost.boost_deadline!
                                    )}
                                </div>
                            ) : (
                                <div className='boosted-false'>
                                    Your position is not boosted
                                </div>
                            )}
                        </div>
                        <hr />
                        <h4>Boost now with:</h4>
                        <select
                            className='form-control input-amount'
                            value={inputMembershipCard}
                            onChange={(e) =>
                                setInputMembershipCard(e.target.value)
                            }
                        >
                            {walletNfts.map((nft) => (
                                <option
                                    key={nft.identifier}
                                    value={nft.identifier}
                                >
                                    {nft.name.replace(
                                        'E.V Membership Access',
                                        'Card'
                                    )}{' '}
                                    -{' '}
                                    {['Diamond', 'Gold'].includes(
                                        nft.metadata?.attributes?.find(
                                            (attr: any) =>
                                                attr.trait_type === 'Color'
                                        )?.value
                                    )
                                        ? nft.metadata?.attributes?.find(
                                              (attr: any) =>
                                                  attr.trait_type === 'Color'
                                          )?.value
                                        : 'Common'}{' '}
                                    (
                                    {
                                        nft.metadata?.attributes?.find(
                                            (attr: any) =>
                                                attr.trait_type ===
                                                '$UTX Boost Value'
                                        )?.value
                                    }
                                    )
                                </option>
                            ))}
                        </select>
                        <button
                            className='btn mt-2 px-4 btn-outline-primary section-toggler-custom'
                            onClick={() => boost()}
                        >
                            Boost
                        </button>
                        {currentUserBoost &&
                            currentUserBoost.boost_deadline! >
                                currentTimestamp && (
                                <div
                                    className='mt-2'
                                    style={{
                                        fontSize: '1rem'
                                    }}
                                >
                                    Boosting with a new card will replace your
                                    current boost
                                </div>
                            )}
                        <div
                            className='mt-2'
                            style={{
                                fontSize: '1rem'
                            }}
                        >
                            Every used card will be burned!
                        </div>
                    </div>
                </div>

                {stakingToken === rewardToken && (
                    <div className='w-100 d-flex card card-dashboard p-4 m-2 text-center'>
                        <h4>
                            Enable{' '}
                            <span className='auto-stake'>AutoRestake</span> by
                            burning a membership card
                        </h4>
                        <div className='fs-6 text-center'>
                            {userAutoRestakeDeadline > currentTimestamp ? (
                                <div className='boosted-true px-3'>
                                    AutoRestake Enabled until{' '}
                                    {timestampToDate(userAutoRestakeDeadline)}
                                </div>
                            ) : (
                                <div className='boosted-false px-3'>
                                    AutoRestake is not enabled
                                </div>
                            )}
                        </div>
                        <div
                            className='mt-2'
                            style={{
                                fontSize: '1rem'
                            }}
                        >
                            Only Common Cards - 6 months validity/card
                            <br />
                            AutoRestake will restake your rewards once a day at
                            00:00 UTC if rewards are above 50 $UNITYX
                        </div>
                        {userAutoRestakeDeadline < currentTimestamp ? (
                            <>
                                <select
                                    className='form-control input-amount'
                                    value={inputMembershipCardAutoRestake}
                                    onChange={(e) =>
                                        setInputMembershipCardAutoRestake(
                                            e.target.value
                                        )
                                    }
                                >
                                    {walletNftsAutoRestake.map((nft) => (
                                        <option
                                            key={nft.identifier}
                                            value={nft.identifier}
                                        >
                                            {nft.name.replace(
                                                'E.V Membership Access',
                                                'Card'
                                            )}{' '}
                                            -{' '}
                                            {['Diamond', 'Gold'].includes(
                                                nft.metadata?.attributes?.find(
                                                    (attr: any) =>
                                                        attr.trait_type ===
                                                        'Color'
                                                )?.value
                                            )
                                                ? nft.metadata?.attributes?.find(
                                                      (attr: any) =>
                                                          attr.trait_type ===
                                                          'Color'
                                                  )?.value
                                                : 'Common'}{' '}
                                        </option>
                                    ))}
                                </select>
                                <button
                                    className='btn mt-2 px-4 btn-outline-primary section-toggler-custom'
                                    onClick={() => optInAutoRestake()}
                                >
                                    Activate AutoRestake
                                </button>
                            </>
                        ) : (
                            <button
                                className='btn mt-2 px-4 btn-outline-primary section-toggler-custom'
                                onClick={() => optOutAutoRestake()}
                            >
                                Stop AutoRestake
                            </button>
                        )}
                        <div
                            className='mt-2'
                            style={{
                                fontSize: '1rem'
                            }}
                        >
                            Every used card will be burned!
                        </div>
                    </div>
                )}

                {(userStaking?.staked_amount?.toString(10) ?? '0') !== '0' && (
                    <div className='w-100 d-flex flex-row text-center justify-content-evenly mb-4 card-container'>
                        <div className='w-50 d-flex card card-dashboard p-4 m-2'>
                            <h4>Unstake:</h4>
                            <div className='input-group mb-2'>
                                <input
                                    type='number'
                                    className='form-control mb-4 input-amount'
                                    placeholder='Enter amount to stake'
                                    value={inputAmountToUnstake}
                                    onChange={(e) =>
                                        setInputAmountToUnstake(e.target.value)
                                    }
                                />
                                <div className='input-group-append'>
                                    <button
                                        className='btn btn-outline-secondary'
                                        type='button'
                                        onClick={() => {
                                            setInputAmountToUnstake(
                                                new BigNumber(
                                                    userStaking?.staked_amount?.toString(
                                                        10
                                                    ) ?? '0'
                                                )
                                                    .dividedBy(
                                                        new BigNumber(10).pow(
                                                            utxDecimals
                                                        )
                                                    )
                                                    .toString(10)
                                            );
                                        }}
                                    >
                                        MAX
                                    </button>
                                </div>
                            </div>
                            <div className='d-flex flex-column custom-gap'>
                                <button
                                    className='btn px-4 btn-outline-primary section-toggler-custom'
                                    onClick={() => unstake()}
                                >
                                    Unstake ({unstakingPeriod / SECONDS_IN_DAY}d
                                    unstaking period)
                                </button>
                                <button
                                    className='btn px-4 btn-outline-primary section-toggler-custom text-danger'
                                    onClick={() => unstake(true)}
                                >
                                    Fast unstake (24h unstaking period -{' '}
                                    {fastUnstakingPenalty / 100}% penalty)
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {userUnstaking.length > 0 && (
                    <div className='w-50 d-flex card card-dashboard p-4 m-2'>
                        {userUnstaking.map((unstakingPosition, i) => (
                            <div
                                className='d-flex flex-column align-items-center gap-30 mb-3'
                                key={'unstaking' + i}
                            >
                                <h4>Unstaked tokens:</h4>
                                <FormatAmount
                                    value={unstakingPosition.amount!.toString(
                                        10
                                    )}
                                    token={stakingToken.split('-')[0]}
                                    digits={4}
                                    decimals={utxDecimals}
                                />
                                <div className='d-flex flex-column custom-gap'>
                                    <button
                                        className='btn px-4 btn-outline-primary section-toggler-custom'
                                        disabled={
                                            unstakingPosition.unstake_deadline! >
                                            currentTimestamp
                                        }
                                        onClick={() =>
                                            withdraw(unstakingPosition.id!)
                                        }
                                    >
                                        {unstakingPosition.unstake_deadline! <=
                                        currentTimestamp
                                            ? 'Withdraw now'
                                            : 'Withdrawable on ' +
                                              timestampToDate(
                                                  unstakingPosition.unstake_deadline!
                                              )}
                                    </button>
                                    {unstakingPosition.unstake_deadline! -
                                        SECONDS_IN_DAY >
                                        currentTimestamp && (
                                        <button
                                            className='btn px-4 btn-outline-primary section-toggler-custom  text-danger'
                                            onClick={() =>
                                                fastWithdraw(
                                                    unstakingPosition.id!
                                                )
                                            }
                                        >
                                            Unstake in 24h (
                                            {(
                                                (fastUnstakingPenalty *
                                                    (unstakingPosition.unstake_deadline! -
                                                        SECONDS_IN_DAY -
                                                        currentTimestamp)) /
                                                (unstakingPosition.unstake_deadline! -
                                                    SECONDS_IN_DAY -
                                                    unstakingPosition.unstake_begin!) /
                                                100
                                            ).toFixed(2)}
                                            % penalty)
                                        </button>
                                    )}
                                    <button
                                        className='btn px-4 btn-outline-primary section-toggler-custom'
                                        onClick={() =>
                                            cancelUnstake(unstakingPosition.id!)
                                        }
                                    >
                                        Cancel unstake
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
