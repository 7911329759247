import React, { useEffect, useState, useRef, memo } from 'react';
import { routeNames } from 'routes';
import { useNavigate } from 'react-router-dom';
import { DashboardLink } from 'components/DashboardLink';
import { graphqlUrl } from 'config';
import MbGold from 'assets/img/mb-gold.png';
import mugev from 'assets/img/merch/mug-ev.png';
import mugmvx from 'assets/img/merch/mug-mvx.png';
import mugoag from 'assets/img/merch/mug-oag.png';
import merchshirt from 'assets/img/merch/tshirts-ev.png';
import RomaTicket from 'assets/img/roma-ticket.png';
import AllContainers from 'assets/img/all-containers.png';
import UtxStake from 'assets/img/UtxStake.png';
import MembCards from 'assets/img/MembCards.png';
import OAG from 'assets/img/OAG.png';
import CYBORG from 'assets/img/cyborg.png';
import TokenChart from 'components/TokenChart';
import CardSwiperAllCollections from 'components/swiperAllCollections';
import request from 'graphql-request';
import { useGetAccount, useGetActiveTransactionsStatus } from 'hooks';
import { Query } from 'types';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';

export const Dashboard = () => {
    const navigate = useNavigate();

    const { address } = useGetAccount();
    const { success, fail } = useGetActiveTransactionsStatus();

    const [ev, setEv] = useState<any>();
    const [totalRewards, setTotalRewards] = useState<any>();

    const fetchData = () => {
        request<Query>(
            graphqlUrl,
            `
            query($user: String) {
                ev {
                  nftStakingPools {
                    _address
                    rewardsForUser(address:$user) {
                        tokenId
                        amount
                    }
                  }
                  tokenStakingPools {
                    _address
                    rewardToken
                    rewardsForUser(address: $user)
                  }   
                }
              }
            `,
            {
                user: address
            }
        )
            .then(({ ev }) => {
                if (!ev) return;
                if (!ev.nftStakingPools) return;
                if (!ev.tokenStakingPools) return;

                setEv(ev);

                const nftRewards = ev.nftStakingPools
                    .map((pool: any) => pool.rewardsForUser)
                    .flat()
                    .filter((reward: any) => reward.amount !== '0')
                    .reduce((acc: any, reward: any) => {
                        if (!acc[reward.tokenId]) {
                            acc[reward.tokenId] = BigInt(0);
                        }
                        acc[reward.tokenId] += BigInt(reward.amount);
                        return acc;
                    }, {});

                const tokenRewards = ev.tokenStakingPools
                    .filter((pool: any) => pool.rewardsForUser !== '0')
                    .reduce((acc: any, pool: any) => {
                        if (!acc[pool.rewardToken]) {
                            acc[pool.rewardToken] = BigInt(0);
                        }
                        acc[pool.rewardToken] += BigInt(pool.rewardsForUser);
                        return acc;
                    }, {});

                const totalRewards = {
                    'UNITYX-3181a2':
                        (nftRewards['UNITYX-3181a2'] ?? BigInt(0)) +
                        (tokenRewards['UNITYX-3181a2'] ?? BigInt(0)),
                    EGLD:
                        (nftRewards['EGLD'] ?? BigInt(0)) +
                        (tokenRewards['EGLD'] ?? BigInt(0))
                };

                setTotalRewards(totalRewards);
            })
            .catch((err: any) => {
                console.error(err);
            });
    };

    useEffect(() => {
        fetchData();
    }, [success, fail]);

    return (
        <div className='dashboard-container staking-cards-container d-flex flex-row flex-wrap my-4 gap-5'>
            <div className='dashboard-left w-100 col-lg-9'>
                <div className='dashboard-left-container d-flex flex-column flex-md-row gap-4'>
                    <DashboardLink
                        title='E.V Cyborgs NFTs'
                        subtitle='Stake your E.V Cyborgs NFTs to earn rewards'
                        img={CYBORG}
                        buttonText='Open'
                        onClick={() => navigate(routeNames.infinityStaking)}
                    />
                    <DashboardLink
                        title='Odd Apes Gang'
                        subtitle='Stake your OAG NFTs to earn rewards'
                        img={OAG}
                        buttonText='Open'
                        onClick={() => navigate(routeNames.oagStaking)}
                    />
                    <DashboardLink
                        title='M. Cards Staking'
                        subtitle='Stake your Membership Cards to earn rewards'
                        img={MembCards}
                        buttonText='Open'
                        onClick={() => navigate(routeNames.collectionsStaking)}
                    />
                </div>
                <div className='dashboard-left-container d-flex gap-4 flex-wrap flex-md-nowrap'>
                    <DashboardLink
                        title='Token Staking'
                        subtitle='Stake your UNITYX tokens to earn rewards'
                        img={UtxStake}
                        buttonText='Open'
                        onClick={() => navigate(routeNames.tokenStaking)}
                    />
                    <DashboardLink
                        title='Roma Tickets'
                        subtitle='Get your chance to win a trip in the Eternal City'
                        img={RomaTicket}
                        buttonText='Open'
                        onClick={() => navigate(routeNames.romaTicket)}
                    />
                    <DashboardLink
                        title='OAG Containers'
                        subtitle='Open your containers for exciting prizes'
                        img={AllContainers}
                        buttonText='Open'
                        onClick={() => navigate(routeNames.containers)}
                    />
                </div>
                <div className='dashboard-merch-boxes dashboard-left-container d-flex justify-content-center gap-4'>
                    <div className='card card-dashboard text-center'>
                        <h2 className='my-3'>E.V Ecosystem Shop</h2>
                        <h3 className='mb-5'>Coming Soon!</h3>
                        <div className='d-flex flex-wrap justify-content-center'>
                            <img
                                src={mugev}
                                alt='mb-gold'
                                className='mb-5'
                                style={{ width: '200px', objectFit: 'contain' }}
                            />
                            <img
                                src={mugmvx}
                                alt='mb-gold'
                                className='mb-5'
                                style={{ width: '200px', objectFit: 'contain' }}
                            />
                            <img
                                src={mugoag}
                                alt='mb-gold'
                                className='mb-5'
                                style={{ width: '200px', objectFit: 'contain' }}
                            />
                            <img
                                src={merchshirt}
                                alt='mb-gold'
                                className='mb-5'
                                style={{ width: '200px', objectFit: 'contain' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='dashboard-right col-12 col-lg-3'>
                <div className='dashboard-right-container d-flex flex-column'>
                    <CardSwiperAllCollections />
                    <div className='dashboard-right-info'>
                        <div className='p-3 text-center'>
                            <h3 className='font-weight-bold pb-3'>
                                UTX Token Info
                            </h3>
                            <div className='d-flex flex-column gap-3'>
                                <div className='d-flex flex-column gap-3'>
                                    <div className='claim-all-card card card-dashboard text-center relative mb-4'>
                                        <h4 className=''>Claim your Rewards</h4>
                                        <div className='claim-amount '>
                                            <div className='amount-to-claim d-flex flex-column w-full'>
                                                <div className='font-weight-bold'>
                                                    <FormatAmount
                                                        value={
                                                            totalRewards?.[
                                                                'UNITYX-3181a2'
                                                            ]
                                                        }
                                                        token={'UNITYX'}
                                                    />
                                                </div>
                                                <div className='font-weight-bold'>
                                                    <FormatAmount
                                                        value={
                                                            totalRewards?.[
                                                                'EGLD'
                                                            ]
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='claim-amount'>
                                            <div className='amount-to-claim  d-flex flex-column'>
                                                <span className='font-weight-bold'></span>
                                                <button
                                                    className='btn btn-primary btn-custom'
                                                    onClick={async () => {
                                                        await refreshAccount();

                                                        const nftTransactions =
                                                            ev.nftStakingPools
                                                                .filter(
                                                                    (p: any) =>
                                                                        p.rewardsForUser?.filter(
                                                                            (
                                                                                r: any
                                                                            ) =>
                                                                                r.amount !==
                                                                                '0'
                                                                        )
                                                                            .length !==
                                                                        0
                                                                )
                                                                .map(
                                                                    (
                                                                        pool: any
                                                                    ) => {
                                                                        return {
                                                                            value: 0,
                                                                            data: 'claimRewards',
                                                                            receiver:
                                                                                pool._address,
                                                                            gasLimit: 25_000_000
                                                                        };
                                                                    }
                                                                );
                                                        const tokenTransactions =
                                                            ev.tokenStakingPools
                                                                .filter(
                                                                    (p: any) =>
                                                                        p.rewardsForUser !==
                                                                        '0'
                                                                )
                                                                .map(
                                                                    (
                                                                        pool: any
                                                                    ) => {
                                                                        return {
                                                                            value: 0,
                                                                            data: 'claim_rewards',
                                                                            receiver:
                                                                                pool._address,
                                                                            gasLimit: 25_000_000
                                                                        };
                                                                    }
                                                                );

                                                        const { sessionId } =
                                                            await sendTransactions(
                                                                {
                                                                    transactions:
                                                                        [
                                                                            ...nftTransactions,
                                                                            ...tokenTransactions
                                                                        ],
                                                                    transactionsDisplayInfo:
                                                                        {
                                                                            processingMessage:
                                                                                'Claiming rewards...',
                                                                            errorMessage:
                                                                                'An error has occured during claiming',
                                                                            successMessage:
                                                                                'Rewards claimed successfully!'
                                                                        }
                                                                }
                                                            );
                                                    }}
                                                >
                                                    Claim All
                                                </button>
                                            </div>
                                        </div>
                                        <button
                                            className='btn btn-primary btn-custom mx-4'
                                            onClick={() =>
                                                navigate(
                                                    routeNames.rewardsHistory
                                                )
                                            }
                                        >
                                            Rewards History
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <TokenChart />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
